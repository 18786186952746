.typing, .typed {
    font-weight: bold; /* Make the text bold */
    display: inline-block;
    transition: font-size 0.5s ease-out;
    font-family: 'Montserrat', sans-serif;
}

.typing {
    font-size: 6rem; /* Size while typing */
    transform-origin: left;
}

.typed {
    font-size: 5rem; /* Size after typing is complete */
}

.cursor {
    background-color: black; /* Make the cursor a black box */
    display: inline-block;
    width: 1ch; /* Width of a single character */
    height: 1em; /* Height based on the current font size */
    vertical-align: middle; /* Align with the bottom of the text */
    margin-left: 4px; /* Optional: add a little space between the text and cursor */
    animation: blink 0.3s infinite; /* Animation for blinking effect */
    transform: translateY(-5px);
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@keyframes revealCircle {
    to {
      clip-path: circle(100% at 50% 50%);
    }
  }

@media (max-width: 768px) {
    .typing {
        font-size: 1.7rem;
    }

    .typed {
        font-size: 1.5rem;
    }
  }

@media (min-width: 769px) and (max-width: 1024px) {
    .typing {
        font-size: 3.5rem; /* Size for iPad screens */
    }

    .typed {
        font-size: 3rem; /* Size for iPad screens */
    }
}
