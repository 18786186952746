#portraitDiv {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

.text-overlay {
    position: absolute;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    z-index: 0;
    font-family: 'Montserrat', sans-serif;
 }

.text-overlay h1 {
    margin: 0;
    font-size: 3.8rem; /* Main text size */
    font-weight: bold;
 }

.text-overlay p {
    font-size: 1.8rem; /* Additional text size */
    font-weight: bold;
}

@media (max-width: 768px) {
  .text-overlay h1 {
      font-size: 2rem;
  }

  .text-overlay p {
      font-size: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .text-overlay h1 {
        font-size: 2.5rem;
    }

    .text-overlay p {
        font-size: 1.25rem;
    }
}
