/* Always include space for the scrollbar to prevent layout shifts */
html {
  background-color: #18181b;
  width: 100%;
  overflow-y: scroll; /* Ensure scrollbar space is always accounted for */
}

/* Style the scrollbar to be transparent by default */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Make the track transparent */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners on the scrollbar thumb */
}

/* Style the scrollbar to be visible when hovered or active */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: #555;
}

/* For other browsers like Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;  /* Make scrollbar transparent by default */
}

/* Hide scrollbar for other non-webkit browsers (as a fallback) */
body::-webkit-scrollbar {
  display: none;
}
